import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const [freeAppData, setFreeAppData] = useState([]);
  const [freeChartData, setFreeChartData] = useState([]);
  const [freeChartUSData, setFreeChartUSData] = useState([]);
  const [paidChartData, setPaidChartData] = useState([]);
  const [paidChartUSData, setPaidChartUSData] = useState([]);
  const [grossingCNData, setGrossingCNData] = useState([]);
  const [grossingUSData, setGrossingUSData] = useState([]);

  useEffect(() => {
    fetchData('https://music.whatshub.top/json/app.json', setFreeAppData);
    fetchData('https://itunes.apple.com/cn/rss/topfreeapplications/limit=50/json', setFreeChartData);
    fetchData('https://itunes.apple.com/us/rss/topfreeapplications/limit=50/json', setFreeChartUSData);
    fetchData('https://itunes.apple.com/cn/rss/topPaidApplications/limit=50/json', setPaidChartData);
    fetchData('https://itunes.apple.com/us/rss/topPaidApplications/limit=50/json', setPaidChartUSData);
    fetchData('https://itunes.apple.com/cn/rss/topGrossingApplications/limit=50/json', setGrossingCNData);
    fetchData('https://itunes.apple.com/us/rss/topGrossingApplications/limit=50/json', setGrossingUSData);

    updateDocumentTitle();
  }, []);

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url);
      const jsonData = await response.json();

      let processedData = [];
      if (url.includes('music.whatshub.top')) {
        processedData = jsonData.map(app => ({
          title: app.title,
          appstore_link: app.appstore_link,
          image: app.image.replace(/(\d+)x(\d+)bb.png/, '128x128bb.png')
        })).slice(0, 20); 
      } else {
        processedData = jsonData.feed.entry.map(appinfo => ({
          title: appinfo['im:name'].label,
          appstore_link: appinfo.link?.[0]?.attributes?.href,
          image: appinfo['im:image'][0].label.replace(/(\d+)x(\d+)bb.png/, '128x128bb.png') 
        }));
      }

      setter(processedData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const updateDocumentTitle = () => {
    document.title = "GetApp - AppStore限时免费应用,免费应用榜,付费应用榜,销量榜";
  };

  const renderAppList = (data, title, path) => (
    <div className="homepage-app-list">
      <h3 style={{ textAlign: 'center' }}>
        <Link to={path}>{title}</Link>
      </h3>
      <div className="homepage-container">
        <div>
          {data.map((app, index) => (
            <div key={index} className="homepage-app-item">
              <div>
                {index === 0 && <img src={app.image} alt={`${app.title} App`} />}
              </div>
              <div>
                <a href={app.appstore_link} target="_blank" rel="noopener noreferrer">{app.title}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="homepage-container">
      {renderAppList(freeAppData, '限时免费App', '/freeapp')}
      {renderAppList(freeChartData, '免费应用CN', '/freechart')}
      {renderAppList(freeChartUSData, '免费应用US', '/freechartus')}
      {renderAppList(paidChartData, '付费应用CN', '/paidchart')}
      {renderAppList(paidChartUSData, '付费应用US', '/paidchartus')}
      {renderAppList(grossingCNData, '畅销榜CN', '/grossingcn')}
      {renderAppList(grossingUSData, '畅销榜US', '/grossingus')}
    </div>
  );
};

export default HomePage;
