import React, { useEffect, useState } from 'react';
const PaidChartUS = () => {
const [, setAppData] = useState([]);
  const [visibleApps, setVisibleApps] = useState(20);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchDataAndRender();
		updateDocumentTitle();
  }, []);
  const fetchDataAndRender = async () => {
    try {
      const response = await fetch('https://itunes.apple.com/us/rss/topPaidApplications/limit=100/json');
      const jsonData = await response.json();
      setData(jsonData.feed.entry);
      const processedData = jsonData.feed.entry.map((appinfo, index) => ({
        ...appinfo,
        title: appinfo['im:name'].label,
        image: appinfo['im:image'][2].label,
        appstore_link: appinfo.link?.[0]?.attributes?.href,
      }));
      setAppData(processedData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  
  const renderChart = () => {
    if (!data) return null;

    return data.slice(0, visibleApps).map((appinfo, index) => {
      const imageUrl = appinfo['im:image'][2].label.replace(/(\d+)x(\d+)bb.png/, '128x128bb.png');
      return (
        <div key={index} className="free-app-item" onClick={() => window.open(appinfo.link[0].attributes.href, '_blank')} role="button">
          <img src={imageUrl} alt={appinfo['im:name'].label} loading="lazy" />
<p>
  {index + 1}. {appinfo['im:name'].label}<br />{appinfo['im:price'].label}
</p>
        </div>
      );
    });
  };
  const loadMore = () => {
    setVisibleApps(prevVisible => prevVisible + 20);
  };
    const updateDocumentTitle = () => {
    document.title = "AppStore美区付费榜 - GetApp";
  };
  return (
    <div className="free-app-container">
      <div className="free-app-list">
        {renderChart()}
      </div>
      <button className="load-more-button" onClick={loadMore}>
        Load More
      </button>
    </div>
  );
};
export default PaidChartUS;
