import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();
  const navRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const closeDropdown = () => {
    setDropdownOpen(false);
  };
  const getPageName = () => {
    switch (location.pathname) {
      case '/':
        return 'GetApp';
      case '/freeapp':
        return '限时免费App';
      case '/freechart':
        return '免费应用CN';
      case '/freechartus':
        return '免费应用US';
      case '/paidchart':
        return '付费应用CN';
      case '/paidchartus':
        return '付费应用US';
      case '/grossingcn':
        return '畅销榜CN';
      case '/grossingus':
        return '畅销榜US';
      default:
        return '';
    }
  };
  return (
    <nav ref={navRef} className="navbar">
      <Link to="/" className="navbar-logo">
        <img src="/icon.svg" alt="Logo" />
        <h1 className="page-name">{getPageName()}</h1>
      </Link>
      <div className="navbar-toggle" onClick={toggleDropdown}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`navbar-links ${dropdownOpen ? 'active' : ''}`}>
        <li onClick={closeDropdown}>
          <Link to="/freeapp">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
            限免应用
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/freechart">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
            免费应用CN
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/freechartus">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
免费应用US
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/paidchart">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
付费应用CN
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/paidchartus">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
付费应用US
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/grossingcn">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
畅销榜CN
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="/grossingus">
		  <img src="l.svg" alt="logo" style={{ width: '16px', height: '16px' }} />
畅销榜US
          </Link>
        </li>
        <li onClick={closeDropdown}>
          <Link to="https://whatshub.top">

		  <img src="00.png" alt="logo" style={{ width: '16px', height: '16px' }} />
		  		  Yfamily
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Navbar;